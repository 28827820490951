import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchBar.css";

const SearchBar = ({ suggestions }) => {
  const [query, setQuery] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const navigate = useNavigate();
  const searchBarRef = useRef(null);

  // Function to handle clicks outside the search bar
  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  // Add and remove event listener for clicks outside the search bar
  useEffect(() => {
    if (showSuggestions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSuggestions]);

  const handleChange = (e) => {
    const input = e.target.value;
    setQuery(input);

    if (input.length > 0) {
      const filtered = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    navigateToPage(suggestion);
  };

  const navigateToPage = (suggestion) => {
    switch (suggestion) {
      case "Home":
      case "Dashboard":
        navigate("/home");
        break;
      case "Attendence":
      case "Today Activity":
      case "Attendance List":
      case "Attendence Sheet":
      case "Attendence Record":
      case "Daily Records":
        navigate("/punch-in-punch-out");
        break;
      case "Leave Record":
      case "Holidays":
      case "Leave History":
      case "Leave Logs":
      case "Casual Leave":
      case "Earned Leave":
      case "Birthday Leave":
      case "Maternity Leave":
      case "Leave Balance Status":
        navigate("/leave-request");
        break;
      case "Shift-record":
      case "Shift-history":
        navigate("/Shift-request");
        break;
      case "Overtime":
      case "CompOff":
      case "Overtime History":
        navigate("/over-time-request");
        break;
      case "Announcement":
      case "Notification":
        navigate("/announcement");
        break;
      case "Payslip":
      case "Salaryslip":
        navigate("/emp-payslip");
        break;
      case "Exit":
      case "E-Exit":
      case "Resignation":
      case "E-Exit History":
        navigate("/exit");
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigateToPage(query);
  };

  return (
    <form className="search-bar" onSubmit={handleSubmit} ref={searchBarRef}>
      <input
        type="search"
        value={query}
        onChange={handleChange}
        placeholder="Search User, Events and more..."
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul className="suggestions-list">
          {filteredSuggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </form>
  );
};

export default SearchBar;
