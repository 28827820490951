import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AccessDenied = () => {
  const navigate = useNavigate();
  const BackToHome = () => {
    navigate("/");
  };
  return (
    <div style={{ textAlign: "center" }}>
      <h1 class="w3-center w3-animate-right">
        You don't have permission to view this page.
      </h1>
      <h1 class="w3-center w3-animate-zoom">🚫🚫🚫🚫</h1>
      <h4 class="w3-center w3-animate-zoom">error code:403 Forbidden</h4>
      <Button onClick={BackToHome}>Back To Home</Button>
    </div>
  );
};

export default AccessDenied;
