import { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import "./header.css";
import "./responsive.css";
import { NavDropdown } from "react-bootstrap";
import GetEmployeesDetails from "./getUserDetails";
import BaseUrlImage from "../baseUrlImage";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import GetUserPermissions from "./getPermissions";
import SearchBar from "./SearchBar";

const Header = () => {
  const { userPermissions } = GetUserPermissions();
  const rolePermissions = userPermissions?.rolePermissions;
  // console.log(rolePermissions);
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(3);
  const [isOpen, setIsOpen] = useState(false);
  const toggleList = () => {
    setIsOpen(!isOpen);
  };
  const { employeeDetails } = GetEmployeesDetails();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const employeeName = userData ? userData.username : "No user logged in";
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  const [isOpenn, setIsopenn] = useState(false);
  const ToggleSidebarr = () => {
    isOpenn === true ? setIsopenn(false) : setIsopenn(true);
  };

  const handleNotificationClick = () => {
    setNotificationCount(0);
    console.log(notificationCount); // Reset notification count to zero
  };

  const suggestions = [
    "Announcement",
    "Notification",
    "Overtime",
    "Overtime History",
    "Dashboard",
    "Home",
    "Attendence",
    "Attendence Sheet",
    "Attendence Record",
    "Shift Record",
    "Shift History",
    "Exit",
    "Payslip",
    "Salaryslip",
    "Leave Record",
    "Holidays",
    "Leave History",
    "Leave Logs",
    "Casual Leave",
    "Earned leave",
    "Birthday Leave",
    "Maternity Leave",
    "CompOff",
    "Leave Balance Status",
    "Resignation",
    "E-Exit",
    "E-Exit History",
    "Work Time Statistics",
    "Today Activity",
    "Attendance List",
    "Daily Records",
  ];

  return (
    <>
      <Navbar className="main_header">
        <div className="logo">
          <Navbar.Brand>
            <Link to="/">
              <img
                src="../../../assets/images/header-img/logo.webp"
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
          {rolePermissions &&
            rolePermissions.includes("Main Admin Panel Menu - Admin") && (
              <div className="hamburger_icon" onClick={ToggleSidebarr}>
                <img
                  src="../../../assets/images/header-img/menu-bar.webp"
                  alt="hamburger_icon"
                />
              </div>
            )}
        </div>

        <div
          className={`mobile-overlay ${isOpenn === true ? "active" : ""}`}
          onClick={ToggleSidebarr}
        ></div>
        <div className={`hide_menubar ${isOpenn === true ? "active" : ""}`}>
          <span className="aside-trigger" onClick={ToggleSidebarr}></span>
          <div className="hide_menu_container">
            <div className="mail_link">
              <div className="mail_message">
                <p>Admin Panel</p>
                <ul>
                  {/* {rolePermissions &&
                    rolePermissions.includes(
                      "Super User Panel In Menu - Admin"
                    ) && (
                      <>
                        <li>
                          <Link
                            to="/SuperUser"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/subscription.png"
                              alt="logo"
                            />
                            <span>Super User</span>
                          </Link>
                        </li>
                      </>
                    )} */}
                  {/* {rolePermissions &&
                    rolePermissions.includes(
                      "Subscription Panel in Menu - Admin"
                    ) && (
                      <>
                        <li>
                          <Link
                            to="/subscription"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/subscription.png"
                              alt="logo"
                            />
                            <span>Subscription</span>
                          </Link>
                        </li>
                      </>
                    )} */}
                  {rolePermissions &&
                    rolePermissions.includes(
                      "Add Organization Panel In Menu - Admin"
                    ) && (
                      <>
                        <li>
                          <Link
                            to="/add-organization"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/organization.png"
                              alt="logo"
                            />
                            <span>Add Organization</span>
                          </Link>
                        </li>
                      </>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes(
                      "Add Department In Menu - Admin"
                    ) && (
                      <>
                        <li>
                          <Link
                            to="/add-department"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/organization.png"
                              alt="logo"
                            />
                            <span>Department</span>
                          </Link>
                        </li>
                      </>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes(
                      "Add Designation In Menu - Admin"
                    ) && (
                      <>
                        <li>
                          <Link
                            to="/add-designation"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/organization.png"
                              alt="logo"
                            />
                            <span>Designation</span>
                          </Link>
                        </li>
                      </>
                    )}

                  {rolePermissions &&
                    rolePermissions.includes("Permissions Panel - Admin") && (
                      <>
                        <li>
                          <Link
                            to="/permissions"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/permission.png"
                              alt="logo"
                            />
                            <span>Permission </span>
                          </Link>
                        </li>
                      </>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("Roles Panel - Admin") && (
                      <>
                        <li>
                          <Link
                            to="/role"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/roles.png"
                              alt="logo"
                            />
                            <span>Roles</span>
                          </Link>
                        </li>
                      </>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("Employees Panel - Admin") && (
                      <>
                        <li>
                          <Link
                            to="/emp-onboarding"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/employee-onboarding.png"
                              alt="logo"
                            />
                            <span>Employee Onboarding</span>
                          </Link>
                        </li>
                      </>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("Reports Panel Main - Admin") && (
                      <>
                        <li>
                          <Link onClick={toggleList}>
                            <img
                              src="../../../assets/images/profile/employee-management.png"
                              alt="logo"
                            />
                            <span>Employee Reports </span>

                            {isOpen ? <SlArrowUp /> : <SlArrowDown />}
                          </Link>
                          {isOpen && (
                            <ul onClick={toggleList}>
                              {rolePermissions &&
                                rolePermissions.includes(
                                  "Edit Attendance - Admin"
                                ) && (
                                  <Link
                                    to="/edit-attendance"
                                    onClick={ToggleSidebarr}
                                  >
                                    <img
                                      src="../../../assets/images/profile/document-management.png"
                                      alt="logo"
                                      className="submenu-icon"
                                    />
                                    <span>Edit Attendance </span>
                                  </Link>
                                )}
                              {rolePermissions &&
                                rolePermissions.includes(
                                  "Attendance Sheet - Admin"
                                ) && (
                                  <Link
                                    to="/attendance-sheet"
                                    onClick={ToggleSidebarr}
                                  >
                                    <img
                                      src="../../../assets/images/profile/document-management.png"
                                      alt="logo"
                                      className="submenu-icon"
                                    />
                                    <span>Attendance Sheet </span>
                                  </Link>
                                )}
                              {rolePermissions &&
                                rolePermissions.includes(
                                  "Attendance Tracking - Admin"
                                ) && (
                                  <>
                                    <Link
                                      to="/attendance-tracking"
                                      onClick={ToggleSidebarr}
                                    >
                                      <img
                                        src="../../../assets/images/profile/attendance-management.png"
                                        alt="logo"
                                        className="submenu-icon"
                                      />
                                      <span>Attendance Tracking </span>
                                    </Link>
                                  </>
                                )}
                              {rolePermissions &&
                                rolePermissions.includes(
                                  "Leave Tracking - Admin"
                                ) && (
                                  <>
                                    <Link
                                      to="/leave-tracking"
                                      onClick={ToggleSidebarr}
                                    >
                                      <img
                                        src="../../../assets/images/profile/leave-management.png"
                                        alt="logo"
                                        className="submenu-icon"
                                      />
                                      <span>Leave Tracking </span>
                                    </Link>
                                  </>
                                )}
                              {rolePermissions &&
                                rolePermissions.includes(
                                  "Shift Tracking - Admin"
                                ) && (
                                  <>
                                    <Link
                                      to="/shift-tracking"
                                      onClick={ToggleSidebarr}
                                    >
                                      <img
                                        src="../../../assets/images/profile/shift-management.png"
                                        alt="logo"
                                        className="submenu-icon"
                                      />
                                      <span>Shift Tracking </span>
                                    </Link>
                                  </>
                                )}
                              {rolePermissions &&
                                rolePermissions.includes(
                                  "Overtime Tracking - Admin"
                                ) && (
                                  <>
                                    <Link
                                      to="/overtime-tracking"
                                      onClick={ToggleSidebarr}
                                    >
                                      <img
                                        src="../../../assets/images/profile/ovrtime-management.png"
                                        alt="logo"
                                        className="submenu-icon"
                                      />
                                      <span>Overtime Tracking </span>
                                    </Link>
                                  </>
                                )}
                            </ul>
                          )}
                        </li>
                      </>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("Document Management - Admin") && (
                      <li>
                        <Link
                          onClick={ToggleSidebarr}
                          to="/employee/documentpanel"
                          className="profile_name"
                        >
                          <img
                            src="../../../assets/images/profile/document-management.png"
                            alt="logo"
                          />
                          <span>Document Management </span>
                        </Link>
                      </li>
                    )}

                  {rolePermissions &&
                    rolePermissions.includes("Leave Panel - Admin") && (
                      <>
                        <li>
                          <Link
                            to="/leave"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/leave-management.png"
                              alt="logo"
                            />
                            <span>Leave Management </span>
                          </Link>
                        </li>
                      </>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("Shift Panel - Admin") && (
                      <>
                        <li>
                          <Link
                            to="/shift"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/shift-management.png"
                              alt="logo"
                            />
                            <span>Shift Management </span>
                          </Link>
                        </li>
                      </>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("Overtime Panel - Admin") && (
                      <>
                        <li>
                          <Link
                            to="/overtime"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/ovrtime-management.png"
                              alt="logo"
                            />
                            <span>Overtime Management </span>
                          </Link>
                        </li>
                      </>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("Notifications Panel - Admin") && (
                      <>
                        <li>
                          <Link
                            to="/notification"
                            className="profile_name"
                            onClick={ToggleSidebarr}
                          >
                            <img
                              src="../../../assets/images/profile/notifications.png"
                              alt="logo"
                            />
                            <span>Notifications </span>
                          </Link>
                        </li>
                      </>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("Policy Panel - Admin") && (
                      <li>
                        <Link to="/policy-view" onClick={ToggleSidebarr}>
                          <img
                            src="../../../assets/images/profile/roles.png"
                            alt="logo"
                          />
                          <span>Policy</span>
                        </Link>
                      </li>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes(
                      "Salary Management Panel- Admin"
                    ) && (
                      <li>
                        <Link to="/salary-view" onClick={ToggleSidebarr}>
                          <img
                            src="../../../assets/images/profile/salary-management.png"
                            alt="logo"
                          />
                          <span>Salary Management </span>
                        </Link>
                      </li>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("Pay Slip panel - Admin") && (
                      <li>
                        <Link to="/salary-slip" onClick={ToggleSidebarr}>
                          <img
                            src="../../../assets/images/profile/PayslipNew.png"
                            alt="logo"
                          />
                          <span>Pay Slip</span>
                        </Link>
                      </li>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("E-Exit Management - Admin") && (
                      <li>
                        <Link to="/exit-report" onClick={ToggleSidebarr}>
                          <img
                            src="../../../assets/images/profile/exit-management.png"
                            alt="logo"
                          />
                          <span>E-Exit Management </span>
                        </Link>
                      </li>
                    )}
                  {rolePermissions &&
                    rolePermissions.includes("Holiday Panel - Admin") && (
                      <li>
                        <Link to="/holiday-view" onClick={ToggleSidebarr}>
                          <img
                            src="../../../assets/images/profile/holiday.png"
                            alt="logo"
                          />
                          <span>Holiday</span>
                        </Link>
                      </li>
                    )}
                </ul>
              </div>
            </div>
            <div className="top_link Log_out_hide">
              <ul>
                <li>
                  <Link onClick={handleLogout}>
                    <img
                      src="../../../assets/images/profile/logout-icon.png"
                      alt="logo"
                    />
                    <span>Log Out </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="header_search">
          <img src="../../../assets/images/header-img/search.png" alt="logo" />
          {/* <input type="search" placeholder="Search User, Events and more..." /> */}
          <SearchBar suggestions={suggestions} />
        </div>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <div className="header_list">
            <ul className="right_wrapper">
              <li>
                <Link href="/">
                  <img
                    src="../../../assets/images/header-img/settings.webp"
                    alt="settings"
                  />
                </Link>
              </li>
              <li>
                <Link to="/announcement">
                  <div
                    className="nottification_bedge"
                    onClick={handleNotificationClick}
                  >
                    <img
                      src="../../../assets/images/header-img/nottifications.webp"
                      alt="nottifications"
                    />

                    <span>{notificationCount}</span>
                  </div>
                </Link>
              </li>

              <li>
                <NavDropdown
                  title={
                    <span>
                      Welcome, <br /> {employeeName}
                    </span>
                  }
                  className="dropdown_list"
                >
                  <NavDropdown.Item onClick={() => navigate("/profile")}>
                    <span>Profile</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout}>
                    <span>Log Out</span>
                  </NavDropdown.Item>
                </NavDropdown>
              </li>

              <li>
                <Link className="profile_user">
                  {employeeDetails?.employee_photo ? (
                    <img
                      className="profile_image"
                      src={BaseUrlImage + employeeDetails?.employee_photo?.path}
                      alt="notifications"
                    />
                  ) : (
                    <img
                      className="profile_image"
                      src="../../../assets/images/header-img/profileimage.jpg"
                      alt="notifications"
                    />
                  )}
                </Link>
              </li>
            </ul>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
