import { useState, useEffect } from "react";
import axios from "axios";
import BaseUrl from "../baseUrl";
import { toast } from "react-toastify";

const GetEmployeesDetails = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const access_token = userData?.accessToken;
  const employeeId = userData ? userData?.empDetails?._id : "No user logged in";
  // console.log("idddddddddddddddddd",employeeId)
  const [employeeDetails, setEmployeesDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  const getEmployeeDetails = async () => {
    try {
      const response = await axios.get(
        BaseUrl + "api/employee/details/" + employeeId,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": access_token,
          },
        }
      );
      if (response.data.error === true) {
        if (response.data.message === "Forbidden Access") {
          localStorage.clear();
          toast.warning("Session expired, logging out...");
          window.location.href = "/";
        } else {
          handleLogout();
          toast.warning(response.data.message);
        }
      } else {
        // console.log(response.data.data);
        setEmployeesDetails(response?.data?.empdata);
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error in request setup:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeDetails();
  }, []);
  return { employeeDetails, loading, getEmployeeDetails };
};

export default GetEmployeesDetails;
