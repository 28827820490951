import React from "react";

export const Loader = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src="./assets/images/attendence/giff.gif"
        style={{
          width: "300px",
          height: "100px",
        }}
        alt="loader"
      />
    </div>
  );
};
